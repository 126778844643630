<template>
  <AppCard>
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>Personal Address</h3>
      </AppCardHeader>
    </template>

    <!-- Selectable Item -->
    <AppList
      v-for="(item, i) in userSelectionData"
      :key="item.id"
      class="custom-radio-input"
      :class="i > 0 ? 'custom-radio-input-top-spacing' : null"
    >
      <!-- Radio input wrapper -->
      <button class="custom-radio-input-content" @click="goToSelectedPage(item.nameRoute)">
        <!-- left content -->
        <div class="custom-radio-input-left">
          <AppIcon :size="item.iconLeft.size" :color="item.iconLeft.color">
            <Component :is="item.iconLeft.name"></Component>
          </AppIcon>
        </div>
        <!-- Center Content -->
        <div class="custom-radio-input-text">
          <h3>{{ item.title }}</h3>
          <p v-if="item.address.length > 0" data-private>{{ item.address }}</p>
        </div>
        <!-- Right content -->
        <div class="custom-radio-input-right" :class="i > 0 ? '-mb-2' : null">
          <AppIcon :size="item.iconRight.size" :color="item.iconRight.color">
            <Component :is="item.iconRight.name"></Component>
          </AppIcon>
        </div>
      </button>
    </AppList>
  </AppCard>
</template>

<script>
import { reactive, ref } from '@vue/composition-api'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'

import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppIcon from '@/components/AppIcon/AppIcon'
import {
  IconChevronLeft,
  IconLocation,
  IconSearch,
  IconChevronRight,
} from '@moneytransfer.ui/euronet-icons'

import AppList from '@/components/AppList/AppList'
import AppListClickItem from '@/components/AppListClickItem/AppListClickItem'
import AppFigure from '@/components/AppFigure/AppFigure'

import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { storeToRefs } from 'pinia'

export default {
  name: 'RegisterBusinessSearch',
  components: {
    AppCard,
    AppCardHeader,
    AppButton,
    AppIcon,
    AppList,
    AppListClickItem,
    AppFigure,
    IconChevronLeft,
    IconChevronRight,
    IconLocation,
    IconSearch,
  },
  setup() {
    const router = useRouter()

    const corporateRegistrationStore = useCorporateRegistrationStore()
    const { countryList, stateList } = storeToRefs(corporateRegistrationStore)

    // Initilize user selection data
    const userSelectionData = ref([])

    // Setup step of page
    corporateRegistrationStore.activeStepIdx = 3
    corporateRegistrationStore.setActiveStepPageTitle('Personal Address')

    // Get business adress data
    const businessAddress = corporateRegistrationStore.getCorporateForm('registeredaddress')
    const formData = [
      // free-format address
      { id: 'line1', value: '' },
      { id: 'line2', value: '' },
      { id: 'line3', value: '' },
      // fixed-format address
      { id: 'streetname', value: '' },
      { id: 'streetnumber', value: '' },
      { id: 'streettype', value: ''},
      { id: 'unit', value: '' },
      { id: 'area', value: '' },
      // shared
      { id: 'city', value: '' },
      { id: 'state', value: '' },
      { id: 'postalcode', value: '' },
      { id: 'country', value: '' },
    ]
    // Set data using formData format
    for (const key in businessAddress) {
      if (Object.hasOwnProperty.call(businessAddress, key)) {
        const address = businessAddress[key]
        formData.forEach((item, i) => {
          if (item.id === key) {
            formData[i].value = address.value || ''
          }
        })
      }
    }

    let setStateName

    if (stateList.value && stateList.value.length > 0 && stateList.value.length > 0) {
      setStateName = stateList.value
        .filter((state) => state.id && state.id === businessAddress.state.value)
        .map(({ name }) => name)
        .toString()
    }

    // Set country name

    let setCountryName

    if (countryList.value && countryList.value.length > 0) {
      setCountryName = countryList.value
        .filter((country) => country.code && country.code === businessAddress.country.value)
        .map(({ name }) => name)
        .toString()
    }

    // Create a full address
    let getFullAdress = {}
    let oneLineAddress = ''

    // Get data address
    formData.forEach((item) => (getFullAdress[item.id] = item.value))

    // Set Full address

    // scenario 1: adress with free format
    if (getFullAdress.line1.length > 0) {
      oneLineAddress = getFullAdress.line1
    }
    if (getFullAdress.line2.length > 0) {
      oneLineAddress = oneLineAddress + ' ' + getFullAdress.line2
    }
    if (getFullAdress.line3.length > 0) {
      oneLineAddress = oneLineAddress + ' ' + getFullAdress.line3
    }

    // Scenario 2: address with fixed format
    if (getFullAdress.streetname.length > 0) {
      oneLineAddress = oneLineAddress + getFullAdress.streetname
    }
    if (getFullAdress.streetnumber.length > 0) {
      oneLineAddress = oneLineAddress + ' ' + getFullAdress.streetnumber
    }
    if (getFullAdress.unit.length > 0) {
      oneLineAddress = oneLineAddress + ' ' + getFullAdress.unit
    }

    // Shared scenarios
    oneLineAddress = oneLineAddress + ', ' + getFullAdress.city
    if (setStateName && setStateName.length > 0 && stateList.value.length !== 0) {
      oneLineAddress = oneLineAddress + ', ' + setStateName
    }
    oneLineAddress = oneLineAddress + ', ' + setCountryName
    oneLineAddress = oneLineAddress + ', ' + getFullAdress.postalcode

    // Capitalize text
    oneLineAddress = oneLineAddress
      .replace(/^\s+|\s+$/g, '')
      .toLocaleLowerCase()
      .replace(/^\w|\s\w/g, (letter) => letter.toUpperCase())

    // Set user selection data
    userSelectionData.value = [
      {
        title: 'Use my Business address',
        address: oneLineAddress,
        nameRoute: 'PersonalAddress',
        iconLeft: {
          name: 'IconLocation',
          color: 'rgba(0, 108, 224, 1)',
          classes: '',
          size: '24',
        },
        iconRight: {
          name: 'IconChevronRight',
          color: 'rgba(92, 102, 123, 1)',
          classes: '',
          size: '24',
        },
      },
      {
        title: 'Search my personal address',
        address: '',
        nameRoute: 'RegisterPersonalAddressSearch',
        iconLeft: {
          name: 'IconSearch',
          color: 'rgba(0, 108, 224, 1)',
          classes: '',
          size: '22',
        },
        iconRight: {
          name: 'IconChevronRight',
          color: 'rgba(92, 102, 123, 1)',
          classes: '',
          size: '24',
        },
      },
    ]

    // Push the route name based on the user selection
    const goToSelectedPage = async (nameRoute) => {
      // Send address data to personalAddress page
      if (nameRoute === 'PersonalAddress') {
        corporateRegistrationStore.setCorporateFormFieldValue({
          name: 'contactaddress',
          formData,
        })
      }
      router.push({ name: nameRoute })
    }

    // Go to personal detail page
    const goBack = () => {
      router.push({ name: 'RegisterPersonalDetails' })
    }

    return {
      userSelectionData,
      goToSelectedPage,
      goBack,
      mq: reactive(useMediaQuery()),
    }
  },
}
</script>

<style lang="postcss" scoped>
.custom-radio-input {
  @apply max-w-md;
  @apply h-auto;
  @apply m-auto;
  @apply border border-gray-light rounded-2xl;
  @apply overflow-visible;
  @apply shadow-ria-1;
  transition: all 250ms;

  &:hover {
    @apply shadow-none;
  }

  &-content {
    @apply flex justify-between;
    @apply w-full;
    @apply py-6 px-2;

    &:focus {
      @apply outline-none;
    }
  }

  &-item {
    @apply py-2;
  }

  &-left {
    @apply pr-2.5 pl-2.5;
  }

  &-text {
    width: 100%;
    @apply text-left;

    h3 {
      @apply type-h3;
      @apply text-base;
    }

    p {
      @apply pt-2 pr-2;
      @apply text-secondary-text type-body;
    }
  }

  &-right {
    @apply pr-2;
    @apply self-center;
  }

  &-top-spacing {
    margin-top: 2rem;
  }
}
</style>
